<template>
  <div id="login">
    <div id="contain">
      <div id="left_card">
        <h1>销售订单查询</h1>
      </div>
<!--            <div id="right_card">-->
<!--              <el-card class="el-card">-->
<!--                <div id="login_container"></div>-->
<!--                &lt;!&ndash;          <h2>欢迎登录</h2>&ndash;&gt;-->
<!--                &lt;!&ndash;          <form  class="login" action="">&ndash;&gt;-->
<!--                &lt;!&ndash;            <input v-shake type="text"  placeholder="请输入账号">&ndash;&gt;-->
<!--                &lt;!&ndash;            <input v-shake type="password"  placeholder="请输入密码">&ndash;&gt;-->
<!--                &lt;!&ndash;          </form>&ndash;&gt;-->
<!--                &lt;!&ndash;          <div class="remember">&ndash;&gt;-->
<!--                &lt;!&ndash;            <input type="radio" name="" id="psd" class="radio"><label for="psd"></label>记住密码&ndash;&gt;-->
<!--                &lt;!&ndash;          </div>&ndash;&gt;-->
<!--                &lt;!&ndash;          <div class="message">&ndash;&gt;-->
<!--                &lt;!&ndash;            <span v-html="error"></span>&ndash;&gt;-->
<!--                &lt;!&ndash;          </div>&ndash;&gt;-->
<!--                &lt;!&ndash;          <div id="btn">&ndash;&gt;-->
<!--                <button class="loginbtn" @click="usreList">扫码登陆</button>-->
<!--                &lt;!&ndash;          </div>&ndash;&gt;-->
<!--              </el-card>-->
<!--            </div>-->
    </div>
<!--    <div class="btn-between" v-if="!phoneLogin">-->
<!--      <a-->
<!--          :href="feishuUrl.value"-->
<!--          class="el-button el-button&#45;&#45;primary el-button&#45;&#45;small">-->
<!--        <i class="UserFilled"></i>飞书登录</a>-->

<!--    </div>-->
  </div>

  <!--    <el-button @click="handleMessage">点击生成二维码</el-button>-->

  <!--  <el-button @click="handleFeishu">点击生成二维码</el-button>-->

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  props: {
    msg: String,
  },
}


</script>

<script setup>
import {onMounted, ref} from "vue";
import axios from 'axios'
import {useRouter} from "vue-router";
import {redirect_uri} from "@/utils/util";

// const appId = "cli_a3ff2e93c239500d";
const appId = "cli_a554154db657100c";


const goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${appId}&redirect_uri=${encodeURI(redirect_uri)}&response_type=code&state=success_login`;


var QRLoginObj;
const handleMessage = function (event) {
  // 使用 matchOrigin 和 matchData 方法来判断 message 和来自的页面 url 是否合法
  // if (QRLoginObj.matchOrigin(event.origin) && QRLoginObj.matchData(event.data)) {
  //   const loginTmpCode = event.data.tmp_code;
  //   // 在授权页面地址上拼接上参数 tmp_code，并跳转
  //   window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
  // }
};
const feishuUrl = ref("");
const searchObj = ref({})
const searchToObj = () => {
  var search = location.search
  var obj = {}
  var str = search.substr(1)
  var arr = str.split('&')
  for (var i = 0; i < arr.length; i++) {
    var newArr = arr[i].split('=')
    obj[newArr[0]] = newArr[1]
  }
  searchObj.value = obj
}

if (typeof window.addEventListener != 'undefined') {
  window.addEventListener('message', handleMessage, false);
} else if (typeof window.attachEvent != 'undefined') {
  window.attachEvent('onmessage', handleMessage);
}
searchToObj(location.search)

if (searchObj.value.code !== undefined) {
  // console.log("code:", searchObj.value.code)
  // axios.post("http://192.168.0.189:28816/lark/qr_login",
  axios.post("https://project.api.tkplusemi.com/lark/qr_login",
      {
        code: searchObj.value.code,
      },).then(function (res) {
    console.log(res.data);
    if (res.data["data"]["token"]) {
      localStorage.setItem("x-token", check_exist(res.data["data"]["token"]))
      localStorage.setItem("username", check_exist(res.data["data"]["user"]["username"]))
      localStorage.setItem("email", check_exist(res.data["data"]["user"]["email"]))
      localStorage.setItem("linux_name", check_exist(res.data["data"]["user"]["linux_name"]))
      localStorage.setItem("employee_no", check_exist(res.data["data"]["user"]["employee_no"]))
      localStorage.setItem("phone", check_exist(res.data["data"]["user"]["phone"]))
      localStorage.setItem("user_id", check_exist(res.data["data"]["user"]["user_id"]))
      setTimeout(function () {
        xitongA();
      }, 5000)
    }
  }).catch(function (err) {
    alert(err)
    console.log("遇到错误，请再次点击发起按钮，多次点击发起无效请联系管理员")
    console.log(err);
    // xitongA();
    return false;
  });
}

function check_exist(str) {
  if (str) {
    return str
  } else {
    return ""
  }
}


// 页面直接加载二维码
onMounted(() => {
  // 1、检查token是否过期，未过期直接跳转首页
  localStorage.clear()
  // 2、无token或token过期，展示二维码
  // QRLoginObj = window.QRLogin({
  //   id: "login_container",
  //   goto: goto,
  //   style: 'width:260px;height:260px;border:none'
  // });

  // 3、 免密直接授权登录
  feishuUrl.value = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${appId}&redirect_uri=${encodeURI(redirect_uri)}&response_type=code&state=success_login`
  if (searchObj.value.code) {
    console.log("searchObj.value.code 已存在：", searchObj.value.code)
  }else {
    window.location.href = feishuUrl.value
  }
});

// window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
const router = useRouter();

const xitongA = () => {
  // console.log("x-token:",localStorage.getItem("x-token"))
  router.push({path: "/order_details"})
  // router.push({path: "/"})
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

#login {
  position: relative;
  width: 100%;
  height: 98vh;
  //background-image: url(./../assets/login_background.gif);
  background-size: 100% 100%;
  background-color: #a7a8bd;

  #contain {
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(5px);
    box-shadow: -5px -5px 10px rgb(39, 65, 65),
    5px 5px 20px aqua;
    /* 5秒 infinite循环播放无限次 linear匀速  */
    animation: animate 5s linear infinite;
  }
}

#contain {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;

  #left_card {
    width: 500px;

    h1 {
      color: white;
      white-space: nowrap;
    }

    span {
      font-size: 1.2rem;
      text-align: center;
      color: white;
      white-space: nowrap;
    }
  }

  #right_card {
    width: 400px;

    .el-card {
      margin: 0 45px;
      border-radius: 25px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

#right_card {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin-bottom: 5px;
  }

  .login {
    input {
      width: 80%;
      height: 45px;
      margin-top: 10px;
      border: 1px solid white;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      font-size: inherit;
      padding-left: 20px;
      outline: none;
    }
  }

  .remember {
    float: right;
    height: 26px;
    text-align: center;
    font-size: 1rem;
    position: relative;

    .radio {
      height: 1rem;
      width: 1rem;
      vertical-align: middle;
      margin-top: -2px;
      opacity: 0;
    }

    label {
      position: absolute;
      left: -2px;
      top: 5px;
      height: 1rem;
      width: 1rem;
      vertical-align: middle;
      margin-top: -2px;
      border-radius: 50%;
      border: 1px solid black;
    }

    //radio选中后修改labe内的内容 :after 选择器在被选元素的内容后面插入内容。
    input:checked + label::after {
      content: "";
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: rgba(207, 38, 38, 0.8);
      border: 1px solid rgba(207, 38, 38, 0.8);
    }
  }

  .message {
    margin-top: 26px;
    font-size: 0.9rem;
    color: red;
  }

  .loginbtn {
    width: 100%;
    height: 35px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgba(207, 38, 38, 0.8);
  }

}

</style>
