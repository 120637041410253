import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus, {Table} from 'element-plus'
import '@/assets/css/global.less'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.use(ElementPlus, {
    locale: zhCn,
}).use(router)
app.mount('#app')

// const debounce = (fn, delay) => {
//     let timer
//     return (...args) => {
//         if (timer) {
//             clearTimeout(timer)
//         }
//         timer = setTimeout(() => {
//             fn(...args)
//         }, delay)
//     }
// }
//
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//     constructor(callback) {
//         callback = debounce(callback, 200);
//         super(callback);
//     }
// }
